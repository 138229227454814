<template>
    <div>
        <div style="display: flex;margin-bottom: 5%">
            <el-date-picker
                    style="width: 35%"
                    v-model="time"
                    type="date"
                    placeholder="请选择日期"
                    format="YYYY/MM/DD"
                    value-format="YYYY-MM-DD"
                    @change="get_clock"
            />
            <div style="margin-top: 1%;margin-left: 3%;color: #130f40;font-size: 1.5rem;font-weight: bolder">
                打卡记录
            </div>
        </div>
        <el-divider />
        <div v-for="(item, index) in clocks" :key="index">
            <div @click="go_detail(item)">
                <div style="font-size: 1.3rem;color: #130f40;font-weight: bolder;text-align: center">
                    {{ item.roomName}}
                </div>
                <div  style="display: flex;width: 98%;justify-content: space-between;margin-top: 5%;margin-left: 1%;color: #535c68">
                    <div>
                        {{ item.userName}}
                    </div>
                    <div>
                        {{ item.workMessage}}
                    </div>
                    <div v-if="item.finishTime !== '-1'">
                        {{ item.workTime.split(' ')[1] }} - {{ item.finishTime.split(' ')[1] }}
                    </div>
                    <div v-if="item.finishTime === '-1'">
                        {{ item.workTime.split(' ')[1] }}
                    </div>
                </div>

            </div>
            <el-divider />
        </div>
    </div>
</template>

<script>
import {ElMessage} from "element-plus";
import {mapGetters} from "vuex";
import VXETable from "vxe-table";
import {setNowDate} from "@/utils/time";

export default {
    name: "clock_index",
    inject: ['reload'],
    computed: {
        ...mapGetters(['GET_BDST']),
        ...mapGetters(['GET_USER'])
    },
    data() {
        return {
            clocks: [],
            time: setNowDate(new Date())
        }
    },
    created() {
        this.get_clock()
    },
    methods: {
        get_clock(){
            this.axios.get('/sysWork/getSysRoomFlag', (response) => {
                this.clocks = response.obj
            },{
                time: this.time
            })
        },
        go_detail(item){
            const time = Date.parse(new Date())
            this.$router.push({
                name: 'Clock_detail',
                params: {
                    key: time,
                    clock_id: item.id
                }
            });
        }
    }
}
</script>

<style>

</style>